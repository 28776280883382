import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Hamilton from '../assets/bib-hamilton-cover.jpg';
import Cats from '../assets/bib-cover.jpg';
import Placeholder from '../assets/placeholder.png';
import Randomizer from '../components/randomizer';
import IdahoBank from '../assets/ads/bankofidaho.jpg';
import LavaAd from '../assets/ads/lava-hot-springs.jpg';
import LithiaFord from '../assets/ads/lithia-ford.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "broadway-in-boise",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#broadway-in-boise",
        "aria-label": "broadway in boise permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Broadway in Boise`}</h1>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publication.`}</p>
    </blockquote>
    {
      /* **************Current SHOWS************** */
    }
    <h2 {...{
      "id": "tonights-show",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tonights-show",
        "aria-label": "tonights show permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tonight’s Show`}</h2>
    <section className="home-list">
      <Link to="/dear-evan-2022" alt="Link to the Dear Evan Hansen digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFwEBAQEBAAAAAAAAAAAAAAAAAwIABP/aAAwDAQACEAMQAAABrwck1VxzpI0Mu8dIIKf/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIT/9oACAEBAAEFAhUTLR5HdhsIjKbGKcHoiIikWqA2T2ZY79n/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIREkFR/9oACAEDAQE/AcFoaocuFs//xAAbEQACAgMBAAAAAAAAAAAAAAAAAQIRAxIhYf/aAAgBAgEBPwFZJ30i7Rr4I//EAB4QAAIBAwUAAAAAAAAAAAAAAAERABAhIhIxMkGR/9oACAEBAAY/AhxvEaCz7jO8VBmjMSxS2nyOf//EABoQAQEBAQEBAQAAAAAAAAAAAAERADEhQbH/2gAIAQEAAT8hLVHBuDrL5g8Y8oB+MbSA+e6ldxwamtO4vRCjm3umAgge1c4IPCeb/9oADAMBAAIAAwAAABBTFkH/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAwEBPxChPWVTdCso1d//xAAYEQADAQEAAAAAAAAAAAAAAAAAASERof/aAAgBAgEBPxDR0sGIYnscElP/xAAeEAEBAQEAAgIDAAAAAAAAAAABEQAhUWEx0XGxwf/aAAgBAQABPxCSNAPKv909fdAn3gbEOczJSBFp5ONUX4u/n510StVyXEZLB5mw2AcAJT94EF6ZUr9br2L6wHYYAkEtweiPKHPW/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/8ac56/bib-cover.webp 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/d3be9/bib-cover.webp 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/87ca7/bib-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/09b79/bib-cover.jpg 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/7cc5e/bib-cover.jpg 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg",
                "alt": "Dear Evean Hansen Broadway in Boise cover",
                "title": "Dear Evean Hansen Broadway in Boise cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Dear Evan Hansen</div>
      </Link>
      <img src={Placeholder} alt="Placeholder for future digital productions" />
    </section>
    <hr />
    {
      /* **************PAST SHOWS************** */
    }
    <h2>Past Shows</h2>
    <section className="home-list">
      <Link to="/anastasia-2022" alt="Link to the Anastasia digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFwEBAQEBAAAAAAAAAAAAAAAAAwIABP/aAAwDAQACEAMQAAABrwck1VxzpI0Mu8dIIKf/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIT/9oACAEBAAEFAhUTLR5HdhsIjKbGKcHoiIikWqA2T2ZY79n/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIREkFR/9oACAEDAQE/AcFoaocuFs//xAAbEQACAgMBAAAAAAAAAAAAAAAAAQIRAxIhYf/aAAgBAgEBPwFZJ30i7Rr4I//EAB4QAAIBAwUAAAAAAAAAAAAAAAERABAhIhIxMkGR/9oACAEBAAY/AhxvEaCz7jO8VBmjMSxS2nyOf//EABoQAQEBAQEBAQAAAAAAAAAAAAERADEhQbH/2gAIAQEAAT8hLVHBuDrL5g8Y8oB+MbSA+e6ldxwamtO4vRCjm3umAgge1c4IPCeb/9oADAMBAAIAAwAAABBTFkH/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAwEBPxChPWVTdCso1d//xAAYEQADAQEAAAAAAAAAAAAAAAAAASERof/aAAgBAgEBPxDR0sGIYnscElP/xAAeEAEBAQEAAgIDAAAAAAAAAAABEQAhUWEx0XGxwf/aAAgBAQABPxCSNAPKv909fdAn3gbEOczJSBFp5ONUX4u/n510StVyXEZLB5mw2AcAJT94EF6ZUr9br2L6wHYYAkEtweiPKHPW/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/8ac56/bib-cover.webp 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/d3be9/bib-cover.webp 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/87ca7/bib-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/09b79/bib-cover.jpg 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/7cc5e/bib-cover.jpg 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg",
                "alt": "Anastasia Broadway in Boise cover",
                "title": "Anastasia Broadway in Boise cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Anastasia</div>
      </Link>
      <Link to="/charlie-2022" alt="Link to the Charlie and the Chocolate Factory digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFwEBAQEBAAAAAAAAAAAAAAAAAwIABP/aAAwDAQACEAMQAAABrwck1VxzpI0Mu8dIIKf/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIT/9oACAEBAAEFAhUTLR5HdhsIjKbGKcHoiIikWqA2T2ZY79n/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIREkFR/9oACAEDAQE/AcFoaocuFs//xAAbEQACAgMBAAAAAAAAAAAAAAAAAQIRAxIhYf/aAAgBAgEBPwFZJ30i7Rr4I//EAB4QAAIBAwUAAAAAAAAAAAAAAAERABAhIhIxMkGR/9oACAEBAAY/AhxvEaCz7jO8VBmjMSxS2nyOf//EABoQAQEBAQEBAQAAAAAAAAAAAAERADEhQbH/2gAIAQEAAT8hLVHBuDrL5g8Y8oB+MbSA+e6ldxwamtO4vRCjm3umAgge1c4IPCeb/9oADAMBAAIAAwAAABBTFkH/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAwEBPxChPWVTdCso1d//xAAYEQADAQEAAAAAAAAAAAAAAAAAASERof/aAAgBAgEBPxDR0sGIYnscElP/xAAeEAEBAQEAAgIDAAAAAAAAAAABEQAhUWEx0XGxwf/aAAgBAQABPxCSNAPKv909fdAn3gbEOczJSBFp5ONUX4u/n510StVyXEZLB5mw2AcAJT94EF6ZUr9br2L6wHYYAkEtweiPKHPW/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/8ac56/bib-cover.webp 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/d3be9/bib-cover.webp 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/87ca7/bib-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/09b79/bib-cover.jpg 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/7cc5e/bib-cover.jpg 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg",
                "alt": "Charlie and the Chocolate Factory",
                "title": "Charlie and the Chocolate Factory",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Charlie and the Chocolate Factory</div>
      </Link>
      <Link to="/hamilton-2021" alt="Link to Frozen Broadway in Boise publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgME/9oADAMBAAIQAxAAAAFemry2WVgcdWmWSwjEf//EABsQAAICAwEAAAAAAAAAAAAAAAABAgMEEzEh/9oACAEBAAEFAljQFh1seHETKG03Is5X7PhsTHYjef/EABcRAQEBAQAAAAAAAAAAAAAAAAASASH/2gAIAQMBAT8BnEuKx//EABoRAAICAwAAAAAAAAAAAAAAAAASAQIRIVH/2gAIAQIBAT8Ba3R5N4Ek/8QAHRAAAgICAwEAAAAAAAAAAAAAAAECIRAREjEyUf/aAAgBAQAGPwLtlSZ6eJ/G9rHISdbNIuJcTyf/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFRkXH/2gAIAQEAAT8hW0/AlzwWuoVqF2RNXgn7LGEKmmBZpgo9EuGm+zPpTmyvr7P/2gAMAwEAAgADAAAAEFfg8P/EABgRAAMBAQAAAAAAAAAAAAAAAAABESFh/9oACAEDAQE/EHhhI6dp0P/EABkRAQEBAAMAAAAAAAAAAAAAAAEAESFRYf/aAAgBAgEBPxDiWHfAcZeN/8QAHxABAQACAgEFAAAAAAAAAAAAAREAITGBQZGxwdHw/9oACAEBAAE/EAEeTX0c5KLGTOZ1njpJqHxjornNvHQbid3rEo++LFCyVj1gv65KKeJhEMPG8TqAoulwQqaIhH0xdX9es//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0db7ea455bcd9851f579ed1abceecb70/8ac56/bib-hamilton-cover.webp 240w", "/static/0db7ea455bcd9851f579ed1abceecb70/d3be9/bib-hamilton-cover.webp 480w", "/static/0db7ea455bcd9851f579ed1abceecb70/87ca7/bib-hamilton-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0db7ea455bcd9851f579ed1abceecb70/09b79/bib-hamilton-cover.jpg 240w", "/static/0db7ea455bcd9851f579ed1abceecb70/7cc5e/bib-hamilton-cover.jpg 480w", "/static/0db7ea455bcd9851f579ed1abceecb70/1a144/bib-hamilton-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0db7ea455bcd9851f579ed1abceecb70/1a144/bib-hamilton-cover.jpg",
                "alt": "Hamilton 2021 Cover",
                "title": "Hamilton 2021 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Hamilton</div>
      </Link>
      <Link to="/cats-2021" alt="Link to Frozen Broadway in Boise publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFwEBAQEBAAAAAAAAAAAAAAAAAwIABP/aAAwDAQACEAMQAAABrwck1VxzpI0Mu8dIIKf/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIT/9oACAEBAAEFAhUTLR5HdhsIjKbGKcHoiIikWqA2T2ZY79n/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIREkFR/9oACAEDAQE/AcFoaocuFs//xAAbEQACAgMBAAAAAAAAAAAAAAAAAQIRAxIhYf/aAAgBAgEBPwFZJ30i7Rr4I//EAB4QAAIBAwUAAAAAAAAAAAAAAAERABAhIhIxMkGR/9oACAEBAAY/AhxvEaCz7jO8VBmjMSxS2nyOf//EABoQAQEBAQEBAQAAAAAAAAAAAAERADEhQbH/2gAIAQEAAT8hLVHBuDrL5g8Y8oB+MbSA+e6ldxwamtO4vRCjm3umAgge1c4IPCeb/9oADAMBAAIAAwAAABBTFkH/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAwEBPxChPWVTdCso1d//xAAYEQADAQEAAAAAAAAAAAAAAAAAASERof/aAAgBAgEBPxDR0sGIYnscElP/xAAeEAEBAQEAAgIDAAAAAAAAAAABEQAhUWEx0XGxwf/aAAgBAQABPxCSNAPKv909fdAn3gbEOczJSBFp5ONUX4u/n510StVyXEZLB5mw2AcAJT94EF6ZUr9br2L6wHYYAkEtweiPKHPW/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/8ac56/bib-cover.webp 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/d3be9/bib-cover.webp 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/87ca7/bib-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/76df2b30461a1fe3d435eae877cad9b7/09b79/bib-cover.jpg 240w", "/static/76df2b30461a1fe3d435eae877cad9b7/7cc5e/bib-cover.jpg 480w", "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/76df2b30461a1fe3d435eae877cad9b7/1a144/bib-cover.jpg",
                "alt": "Cats",
                "title": "Cats",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Cats</div>
      </Link>
    </section>
    <br />
    <br />
    <hr />
    <br />
    <OutboundLink href="https://www.bankofidaho.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=BroadwayBoise" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <img src={IdahoBank} alt="Bank of Idaho ad" />
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="https://lithialincolnofboise.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=BroadwayBoise" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <img src={LithiaFord} alt="Lithia Ford Lincoln of Boise ad" />
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="https://lavahotsprings.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=BroadwayBoise" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <img src={LavaAd} alt="Lava Hot Springs" />
    </OutboundLink>
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      